import React, {useState, useEffect} from "react";
import { Hero } from "../../sections/Hero/Hero";
import { Historia } from "../../sections/Historia/Historia";
import { Cervezas } from "../../sections/Cervezas/Cervezas";
import { Suscribete } from "../../sections/Suscribete/Suscribete";
import { Contactanos } from "../../sections/Contactanos/Contactanos";
import { PCulture } from "../../sections/PCulture/PCulture";
import { PNews } from "../../sections/PNews/PNews";

import bluetext from "../../assets/img/text/Descubre.png";
import redtext from "../../assets/img/text/Origen.png";

import "./Home.scss";
import { Leyendas } from "../../sections/Leyendas/Leyendas";

export const Home = () => {
  const [dataDiscover, setDataDiscover] = useState(null);
  const [dataInvitation, setDataInvitation] = useState(null);

  useEffect(() => {
    const requestOptionsDiscover = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query : `
          query q($id: ID!, $idType: OneSectionIdType!) {
            oneSection(id: $id, idType: $idType) {
              discoverInvitationSectionFields {
                background {
                  slug
                  sourceUrl
                }
                leftImage {
                  slug
                  sourceUrl
                }
                rightImage {
                  slug
                  sourceUrl
                }
                text {
                  slug
                  sourceUrl
                }
              }
            }
          }
        `,
        variables: {
          id: 'discover-section',
          idType: 'SLUG'
        }
      })
    };

    const requestOptionsInvitation = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query : `
          query q($id: ID!, $idType: OneSectionIdType!) {
            oneSection(id: $id, idType: $idType) {
              discoverInvitationSectionFields {
                background {
                  slug
                  sourceUrl
                }
                leftImage {
                  slug
                  sourceUrl
                }
                rightImage {
                  slug
                  sourceUrl
                }
                text {
                  slug
                  sourceUrl
                }
              }
            }
          }
        `,
        variables: {
          id: 'invitation-section',
          idType: 'SLUG'
        }
      })
    };

    const dataFetch = async () => {
      const dataDiscover = await (
        await fetch("https://api.cervezapatagonia.cl/index.php?graphql", requestOptionsDiscover)
      ).json();

      const dataInvitation = await (
        await fetch("https://api.cervezapatagonia.cl/index.php?graphql", requestOptionsInvitation)
      ).json()

      setDataDiscover(dataDiscover);
      setDataInvitation(dataInvitation);
    };

    dataFetch();
  }, []);

  return (
    <main>
      <Hero />
      <section className="blue" style={{backgroundImage: dataDiscover ? dataDiscover.data.oneSection.discoverInvitationSectionFields.background.sourceUrl : ''}}>
        <img
          alt="Descubre el origen de letendas que llegaron a revolucionar la familia de saores lager épicos"
          src={dataDiscover ? dataDiscover.data.oneSection.discoverInvitationSectionFields.text.sourceUrl : bluetext}
        />
      </section>
      <Historia />
      <Cervezas />
      <Leyendas />
      <Suscribete />
      <PCulture />
      <PNews />
      <section className="red">
        <img
          alt="Te invitamos a descubrir el origen de tu leyenda"
          src={dataInvitation ? dataInvitation.data.oneSection.discoverInvitationSectionFields.text.sourceUrl : redtext}
        />
      </section>

      <Contactanos />
    </main>
  );
};
