import React from "react";
import { useLocation } from "react-router-dom";
import "./News.scss";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";

import imgfooter from "../../assets/img/deco/PATAGONIA_BANNER.png";
import img1 from "../../assets/img/PNews/PS - News.png";
import img2 from "../../assets/img/PNews/Lata - News.png";
import img3 from "../../assets/img/PNews/FP2 - News.png";
import img4 from "../../assets/img/PNews/FP - News.png";

export const News = (props) => {
  const location = useLocation();
  console.log(location.pathname);

  let content;
  let imageBanner;

  if (location.pathname === "/news/1") {
    imageBanner = <img src={img1} alt="" className="img-banner" />;
    content = (
      <>
        <h1 className="title">Lata edición especial Primavera Sound</h1>
        <p>
          Para celebrar la primera edición del festival Primavera Sound en
          Chile, lanzamos una lata edición limitada de nuestra variedad Hoppy
          Lager con un diseño especial inspirado en los colores del evento.
          <br />
          <br />
          Todo comenzó con el anuncio oficial de nuestra participación en el
          festival en nuestra cuenta de Instagram. Quisimos hacerlo épico, así
          que montamos el cartel en una lata de cerveza, nuestros seguidores se
          entusiasmaron con la idea, tomamos en cuenta sus comentarios y con el
          paso de los días lo que partió como una publicación en redes sociales
          comenzó a tomar forma de verdad.
          <br />
          <br />
          Para hacerla realidad nos unimos a Ball Corporation, los creadores del
          movimiento #QUIEROLATA, que en tiempo récord tomaron el desafío y lo
          hicieron posible. Todo gracias a su nueva tecnología lanzada este año:
          Ball Digital Printing, que permite producir latas con calidad
          fotográfica y millones de colores para producir etiquetas únicas, como
          es el caso de nuestra lata edición limitada Primavera Sound,
          disponible exclusivamente en el festival durante sus tres días para
          transformar la experiencia en algo legendario, representando de manera
          íntegra a través del diseño, el match perfecto entre cerveza y música.
          <br />
          <br />
          Un invitado que sin duda llamó la atención de los asistentes y llenó
          nuestras redes sociales de fotos y registros realizados por la
          comunidad disfrutando de un instante único con una lata pensada 100%
          en ellos y en transformar cada momento es algo épico.
        </p>
      </>
    );
  } else if (location.pathname === "/news/2") {
    imageBanner = <img src={img2} alt="" className="img-banner" />;
    content = (
      <>
        <h1 className="title">La cerveza oficial de Primavera Sound</h1>
        <p>
          El pasado mes de Noviembre disfrutamos de tres días legendarios,
          porque somos la cerveza oficial del festival Primavera Sound y tuvimos
          el honor de estar presentes con nuestros sabores lager épicos en su
          primera edición en Chile.
          <br />
          <br />
          Primavera Sound es un festival con historia. Nace en Barcelona el año
          2001, y con una trayectoria de más de 20 años, en 2022 se abrió al
          mundo de la mano de artistas como Arctic Monkeys, Lorde, Bjork, Travis
          Scott, Pixies, Jack White, entre otras bandas legendarias que dieron
          vida a tres jornadas desplegadas en cinco escenarios, con un cartel
          marcado por la calidad y diversidad, que convocó a miles de personas a
          pesar de la lluvia en el Parque Bicentenario de Cerrillos.
          <br />
          <br />
          Porque sabemos que la música se disfruta más con cerveza, nos
          preparamos para ofrecer una experiencia única tanto en el Beer Garden
          PATAGONIA, como en cada uno de los puntos donde se encontraban
          nuestras barras, totalmente equipadas no sólo con nuestras variedades
          lager: Hoppy, Red, Lotus y Black, también complementadas con
          atractivas zonas de descanso para disfrutar como corresponde de un
          momento épico con una cerveza Patagonia en la mano y en compañía de
          los amigos, un merecido descanso para recargar energías y seguir
          vibrando con la mejor música en vivo.
          <br />
          <br />
          Una experiencia difícil de superar, pero que estuvo a la altura de
          todas las expectativas, transformándose en uno de los eventos más
          importantes e inolvidables del año. ¿Te lo perdiste? ¡Se vienen nuevas
          oportunidades! No paramos, nos seguiremos tomando la Primavera.
          Quédate con nosotros.
        </p>
      </>
    );
  } else if (location.pathname === "/news/3") {
    imageBanner = <img src={img3} alt="" className="img-banner" />;
    content = (
      <>
        <h1 className="title">El regreso de Fauna Primavera.</h1>
        <p>
          Después de un receso producto de la pandemia, vuelve Fauna Primavera,
          uno de los festivales nacionales más importantes del año y uno de
          nuestros partners más notables cuando se trata de baile, música y
          bandas legendarias, por eso este año quisimos hacerlo distinto y no
          solo pusimos la cerveza y una experiencia épica, también llevamos
          bandas propias, regalos y a nuestras criaturas legendarias a
          desordenar el ambiente.
          <br />
          <br />
          Después de casi dos meses de concurso que partieron con una
          publicación en nuestra cuenta en Instagram y numerosos mensajes de
          bandas postulando, fueron 12 bandas nacionales emergentes las
          escogidas por un jurado para presentarse en vivo en diversos lugares
          de Santiago como Oculto Bar, Mastica y Peluquería Francesa, para que
          finalmente quedaran solo 10 bandas para tocar de manera profesional en
          el escenario Patagonia en Movistar Arena. Salares, Dime Iris,
          Guillermo Paf, Los Montgomery, Juani Mustard, Alicia y Las Hormigas,
          Fonosida, Kolumbia, Chico Bestia y Confío en tus amigos, jóvenes
          talentosos que llenaron de música y energía nuestro escenario y
          acompañaron de la mejor forma a quienes disfrutaban de una Cerveza
          Patagonia en nuestra barra 360°.
          <br />
          <br />
          Mientras al otro lado nuestra tienda de merch esperaba por los fans
          más entusiastas que después de cada cerveza recibieron un tatuaje
          legendario temporal y se la jugaron llevándolo con orgullo en sus
          cuerpos para canjearlo por poleras, llaveros, gorros, entre otros
          regalos especiales de la marca.
          <br />
          <br />
          También sorprendimos al público soltando a nuestras criaturas
          legendarias a hacer de las suyas. Megaloceros, Mylodon, Smilodon y
          Chilenosaurus, más simpáticos que nunca, cambiaron abrazos por selfies
          y bailaron entre el público al ritmo del neo perreo.
          <br />
          <br />
          Tres días de momentos inolvidables, de historias, amigos, canciones
          favoritas, fotos y preocuparse por el medio ambiente a través del
          reciclaje de nuestras latas. Seguimos evolucionando la manera de
          disfrutar de los sabores lager épicos. Por más Primaveras legendarias,
          no paramos.
        </p>
      </>
    );
  } else if (location.pathname === "/news/4") {
    imageBanner = <img src={img4} alt="" className="img-banner" />;
    content = (
      <>
        <h1 className="title">Los mejores en Chile y el mundo.</h1>
        <p>
          Cerramos el año con la satisfacción de haber participado en The World
          Drinks Awards, premios mundiales que se encargan año a año de
          seleccionar a los mejores exponentes en la industria de la cerveza
          mundial con el fin de promocionar bebidas de calidad entre
          consumidores y comercio especializado, que además cuenta con el
          respaldo de TheDrinksReport.com, el medio de información n° 1 del
          mundo para profesionales de las bebidas.
          <br />
          <br />
          Así fue como durante el mes de Agosto, llegaron noticias épicas y
          nuestra Black Lager, una cerveza de color marrón oscuro con pequeños
          reflejos rubíes donde destacan las notas a chocolate oscuro, bizcocho,
          suave caramelo y café tostado de la malta, con su amargor elegante que
          permite un balance excepcional con una sensación redonda, pero
          refrescante, mostrando gran versatilidad, lo que la llevó a no solo
          ser elegida como la mejor Black Lager de Chile, también la hizo dueña
          de la medalla de plata por su diseño a nivel mundial, reconociendo la
          belleza legendaria de nuestro Megaloceros, que se luce sacando lo
          mejor del lado oscuro de una cerveza única.
          <br />
          <br />
          Por su parte nuestra variedad Red Lager, una cerveza refrescante que
          se destaca por sus brillantes tonos cobrizos y una espuma color
          marfil, que mientras sientes su textura, va revelando exquisitas capas
          de aromas y sabores a caramelo, bizcocho, frutos secos y aromas
          florales del lúpulo, con estas credenciales también tuvo su
          oportunidad de destacar con el reconocimiento de plata en Chile en la
          categoría Lager Vienna.
          <br />
          <br />
          Y así es como nuestro esfuerzo por elaborar sabores inigualables y
          épicos de calidad, se mantienen intactos y revitalizados para seguir
          representando a la industria nacional en el mundo, demostrando que en
          la Patagonia se encuentra el verdadero origen de nuestra leyenda.
          ¡Vamos por más!
          <br />
          <br />
        </p>
      </>
    );
  }

  return (
    <>
      <Header />
      <main className="newsdetail">
        <h1>
          <span>News</span>
        </h1>
        {imageBanner}
        {content}
        <img className="img-footer" src={imgfooter} alt="" />
      </main>
      <Footer />
    </>
  );
};
