import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

export const Footer = () => {
  return (
    <footer className="footer">
      <ul>
        <li>
          <small>Cerveza Patagonia, todos los derechos reservados.</small>
        </li>
        <li>
          <Link to="/tyc">Términos y condiciones</Link>
        </li>
        <li>
          <Link to="/politicasprivacidad">Politicas de Privacidad</Link>
        </li>
      </ul>
    </footer>
  );
};
