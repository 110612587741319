import React, {useState, useEffect} from "react";
import "./Suscribete.scss";

export const Suscribete = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query : `
          query q($id: ID!, $idType: OneSectionIdType!) {
            oneSection(id: $id, idType: $idType) {
              subscribeSectionFields {
                title
                description
                inputName
                inputEmail
                inputButton
                termsAndConditions
                background {
                  slug
                  sourceUrl
                }
                leftImage {
                  slug
                  sourceUrl
                }
              }
            }
          }
        `,
        variables: {
          id: 'subscribe-section',
          idType: 'SLUG'
        }
      })
    };

    const dataFetch = async () => {
      const data = await (
        await fetch("https://api.cervezapatagonia.cl/index.php?graphql", requestOptions)
      ).json();

      setData(data);
    };

    dataFetch();
  }, []);

  return (
    <section className="suscribe" id="suscribete">
      <div className="content">
        <div className="text">
          <h2>{data ? data.data.oneSection.subscribeSectionFields.title : 'Suscríbete'}</h2>
          <p>
            {data ? data.data.oneSection.subscribeSectionFields.description : 'Y recibe información sobre nuevos eventos, lanzamientos, productos y más sorpresas.'}
          </p>
        </div>
        <form>
          <div className="inputs">
            <div className="group-input">
              <label htmlFor=""></label>
              <input type="text" placeholder={data ? data.data.oneSection.subscribeSectionFields.inputName : 'Nombre'} />
            </div>
            <div className="group-input">
              <label htmlFor=""></label>
              <input type="email" placeholder={data ? data.data.oneSection.subscribeSectionFields.inputEmail : 'Email'} />
            </div>
          </div>
          <div className="group-checkbox-group-submit">
            <div className="group-checkbox">
              <input type="checkbox" name="tyc" id="tyc" />
              <label htmlFor="tyc">
                Acepto los {' '}
                <a href="http://" target="_blank" rel="noopener noreferrer">
                  términos y condiciones.
                </a>
              </label>
            </div>
            <button type="submit">{data ? data.data.oneSection.subscribeSectionFields.inputButton : 'Suscribirse'}</button>
          </div>
        </form>
      </div>
    </section>
  );
};
