import React from "react";
import { Link } from "react-router-dom";

import "./CardNews.scss";

export const CardNews = (props) => {
  return (
    <article className="card-news">
      <img src={props.pnewsimg} alt={props.pnewsalt} className="card-img" />
      <div className="card-content">
        <p className="card-title">{props.pnewstitle}</p>
        <p className="card-description">{props.pnewsdescription}</p>

        <Link to={`/news/${props.id}`} className="card-btn">
          Ver más
        </Link>
      </div>
    </article>
  );
};
