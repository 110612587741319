import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import logo from "../../assets/img/icon/logo.svg";
import fb from "../../assets/img/icon/fb.svg";
import ins from "../../assets/img/icon/ins.svg";
import yt from "../../assets/img/icon/yt.svg";

import "./Header.scss";

export const Header = () => {
  const [data, setData] = useState(null);
  const [scroll, setScroll] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const handleOpenMenu = () => {
    setIsOpenMenu(!isOpenMenu);

    document.body.classList.toggle("no-scroll", !isOpenMenu);
  };

  const closeMenu = () => {
    setIsOpenMenu(false);

    document.body.classList.remove("no-scroll");
  };

  function scrollTo(id) {
    const element = document.getElementById(id);
    const headerOffset = 60;
    const elementPosition = element.offsetTop;
    const offsetPosition = elementPosition - headerOffset;

    document.documentElement.scrollTop = offsetPosition;
    document.body.scrollTop = offsetPosition;
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 60);
    });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query : `
          query q($id: ID!, $idType: OneSectionIdType!) {
            oneSection(id: $id, idType: $idType) {
              navbarSectionFields {
                iconFacebook {
                  slug
                  sourceUrl
                }
                iconInstagram {
                  slug
                  sourceUrl
                }
                iconYoutube {
                  slug
                  sourceUrl
                }
                home
                history
                variety
                subscribe
                buyButton
                urlBuyButton
              }
            }
          }
        `,
        variables: {
          id: 'navbar-section',
          idType: 'SLUG'
        }
      })
    };

    const dataFetch = async () => {
      const data = await (
        await fetch("https://api.cervezapatagonia.cl/index.php?graphql", requestOptions)
      ).json();

      setData(data);
    };

    dataFetch();
  }, []);

  return (
    <header className={scroll ? "header bg-pink" : "header"} >
      <NavLink className="logo" to="/">
        <img alt="Logo Cerveza Patagonia" src={logo} />
      </NavLink>

      <button
        className={`btn-menu ${isOpenMenu ? "open" : ""}`}
        id="btnMenu"
        title="abrir menu"
        onClick={handleOpenMenu}
      >
        <span />
        <span />
        <span />
      </button>

      <a
        className="btn-buy"
        href={data ? data.data.oneSection.navbarSectionFields.urlBuyButton : ''}
        rel="noreferrer"
        target="_blank"
      >
        <i className="fa-solid fa-cart-shopping"></i>
        {data ? data.data.oneSection.navbarSectionFields.buyButton : ''}
      </a>

      <div className={`menu ${isOpenMenu ? "open" : ""}`}>
        <nav>
          <ul className="nav">
            <li>
              <NavLink
                end
                className={({ isActive }) => (isActive ? "active" : undefined)}
                to="/"
                onClick={closeMenu}
              >
                {data ? data.data.oneSection.navbarSectionFields.home : ''}
              </NavLink>
            </li>

            <li>
              <a href="#historia" onClick={closeMenu}>
                {data ? data.data.oneSection.navbarSectionFields.history : ''}
              </a>
            </li>

            <li>
              <a href="#variedades" onClick={() => scrollTo('blacklager')}>{data ? data.data.oneSection.navbarSectionFields.variety : ''}</a>
              <ul className="subnav">
                <li>
                  <a href="#blacklager" onClick={closeMenu}>
                    Black Lager
                  </a>
                </li>
                <li>
                  <a href="#redlager" onClick={closeMenu}>
                    Red Lager
                  </a>
                </li>
                <li>
                  <a href="#hoppylager" onClick={closeMenu}>
                    Hoppy Lager
                  </a>
                </li>
                <li>
                  <a href="#lotuslager" onClick={closeMenu}>
                    Lotus Lager
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <a href="#suscribete" onClick={closeMenu}>
                {data ? data.data.oneSection.navbarSectionFields.subscribe : ''}
              </a>
            </li>

            <li>
              <a href="#culture" onClick={closeMenu}>
                Patagonia Culture
              </a>
            </li>

            <li>
              <a href="#news" onClick={closeMenu}>
                Patagonia News
              </a>
            </li>

            <li>
              <a href="#contacto" onClick={closeMenu}>
                Contacto
              </a>
            </li>
          </ul>
        </nav>

        <ul className="rrss">
          <li>
            <a
              href="https://www.facebook.com/cerveza.patagonia.chile"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={data ? data.data.oneSection.navbarSectionFields.iconFacebook.sourceUrl : fb} alt={data ? data.data.oneSection.navbarSectionFields.iconFacebook.slug : ''} />
            </a>
          </li>

          <li>
            <a
              href="https://www.instagram.com/cerveza.patagonia.cl/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={data ? data.data.oneSection.navbarSectionFields.iconInstagram.sourceUrl : ins} alt={data ? data.data.oneSection.navbarSectionFields.iconInstagram.slug : ''} />
            </a>
          </li>

          <li>
            <a
              href="https://www.youtube.com/@cervezapatagoniachile9252/featured"
              rel="noopener noreferrer"
              target="_blank"
            >
               <img src={data ? data.data.oneSection.navbarSectionFields.iconYoutube.sourceUrl : yt} alt={data ? data.data.oneSection.navbarSectionFields.iconYoutube.slug : ''} />
            </a>
          </li>
        </ul>
      </div>

      <div className={`menu-desktop ${isOpenMenu ? "open" : ""}`}>
        <nav>
          <ul className="nav">
            <li>
              <NavLink
                end
                className={({ isActive }) => (isActive ? "active" : undefined)}
                to="/"
                onClick={closeMenu}
              >
                {data ? data.data.oneSection.navbarSectionFields.home : ''}
              </NavLink>
            </li>

            <li>
              <a href="#historia" onClick={closeMenu}>
                {data ? data.data.oneSection.navbarSectionFields.history : ''}
              </a>
            </li>

            <li>
              <a href="#variedades"> {data ? data.data.oneSection.navbarSectionFields.variety : ''}</a>
              <ul className="subnav">
                <li>
                  <a href="#blacklager" onClick={() => scrollTo('blacklager')}>
                    Black Lager
                  </a>
                </li>
                <li>
                  <a href="#redlager" onClick={closeMenu}>
                    Red Lager
                  </a>
                </li>
                <li>
                  <a href="#hoppylager" onClick={closeMenu}>
                    Hoppy Lager
                  </a>
                </li>
                <li>
                  <a href="#lotuslager" onClick={closeMenu}>
                    Lotus Lager
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <a href="#suscribete" onClick={closeMenu}>
                Suscríbete
              </a>
            </li>

            <li>
              <a href="#culture" onClick={closeMenu}>
                Patagonia Culture
              </a>
            </li>

            <li>
              <a href="#news" onClick={closeMenu}>
                Patagonia News
              </a>
            </li>

            <li>
              <a href="#contacto" onClick={closeMenu}>
                Contacto
              </a>
            </li>
          </ul>
        </nav>

        <ul className="rrss">
          <li>
            <a href="http://" rel="noopener noreferrer" target="_blank">
              <img src={fb} alt="" />
            </a>
          </li>

          <li>
            <a href="http://" rel="noopener noreferrer" target="_blank">
              <img src={ins} alt="" />
            </a>
          </li>

          <li>
            <a href="http://" rel="noopener noreferrer" target="_blank">
              <img src={yt} alt="" />
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};
