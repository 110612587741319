import React from "react";
import "./CardCulture.scss";

export const CardCulture = (props) => {
  return (
    <article className="card-culture">
      <img src={props.pcultureimg} alt="" className="card-img" />
      <div className="card-content">
        <p className="card-title">{props.pculturetitle}</p>
        <p className="card-description">{props.pculturedescription}</p>
      </div>
    </article>
  );
};
