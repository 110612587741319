import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import { AgeVerify } from "./components/AgeVerify/AgeVerify";
import { Suscribe } from "./components/Suscribe/Suscribe";
import { Tyc } from "./pages/Tyc/Tyc";
import { Politicasdeprivacidad } from "./pages/Politicasdeprivacidad/Politicasdeprivacidad";
import { News } from "./pages/News/News";
import { Error404 } from "./pages/Error404/Error404";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AgeVerify />
    <Suscribe />
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="tyc" element={<Tyc />} />
      <Route path="politicasprivacidad" element={<Politicasdeprivacidad />} />
      <Route path="news/:id" element={<News />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </BrowserRouter>
);
