import React, { useEffect, useState } from "react";
import "./Suscribe.scss";

export const Suscribe = () => {
  const [openModalS, setOpenModalS] = useState("");

  useEffect(() => {
    // const isSuscribe = localStorage.getItem("suscribe");

    const closeBtn = document.querySelector("#closeModal");

    closeBtn.addEventListener("click", (e) => {
      e.preventDefault();
      setOpenModalS(true);
      document.querySelector(".suscribe-modal").classList.add("none");
      // localStorage.setItem("isSuscribe", true);
    });
  }, [openModalS]);

  return (
    <div className={!setOpenModalS ? "suscribe-modal none" : "suscribe-modal"}>
      <div className="container-modal">
        <button className="close" type="button" id="closeModal" title="cerrar">
          <i className="fa-regular fa-x"></i>
        </button>

        <div className="content-modal">
          <h2>Suscríbete</h2>
          <p>
            Y recibe información sobre nuevos eventos, lanzamientos, productos y
            más sorpresas.
          </p>

          <form>
            <div className="group-input">
              <label htmlFor=""></label>
              <input type="text" placeholder="Nombre" />
            </div>
            <div className="group-input">
              <label htmlFor=""></label>
              <input type="email" name="" id="" placeholder="Email" />
            </div>
            <div className="group-checkbox">
              <input type="checkbox" name="" id="" />
              <label htmlFor="">
                Acepto los{" "}
                <a href="http://" target="_blank" rel="noopener noreferrer">
                  términos y condiciones.
                </a>
              </label>
            </div>
            <button type="submit">Suscribirse</button>
          </form>
        </div>
      </div>
    </div>
  );
};
