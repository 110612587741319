import React, {useState, useEffect} from "react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { CardCulture } from "../../layouts/CardCulture/CardCulture";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./PCulture.scss";

import img1 from "../../assets/ctangana.png";
import img2 from "../../assets/aerobica.png";
import img3 from "../../assets/festival.png";

SwiperCore.use([Pagination, Navigation]);

export const PCulture = (props) => {
  const [dataCulture, setDataCulture] = useState(null);

  useEffect(() => {
    const requestOptionsCulture =  {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query : `
          query {
            cultureSections {
              nodes {
                cultureElementSectionFields {
                  description
                  fieldGroupName
                  title
                  image {
                    sourceUrl
                    slug
                  }
                }
              }
            }
          }
        `,
      })
    }

    const dataFetch = async () => {
      const dataCulture = await (
        await fetch("https://api.cervezapatagonia.cl/index.php?graphql", requestOptionsCulture)
      ).json();

      setDataCulture(dataCulture.data.cultureSections.nodes);
    };

    dataFetch();
  }, []);

  return (
    <section className="culture" id="culture">
      <div className="text">
        <h2>
          Patagonia <span>Culture.</span>
        </h2>
        <p className="title-culture">#CervezaPatagoniaCL</p>
        <br />
        {/* <p className="text-culture">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
          volutpat.
        </p> */}
        {/* <button className="button-culture" type="button">
          Ver más
        </button> */}
      </div>
      <Swiper
        pagination={true}
        navigation={true}
        className="cultureSwiper"
        breakpoints={{
          320: {
            slidesPerView: 1.3,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },

          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
        {dataCulture ? dataCulture.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <CardCulture
                pcultureimg={dataCulture ? item.cultureElementSectionFields.image.sourceUrl : img1}
                pculturetitle={dataCulture ? item.cultureElementSectionFields.title : ''}
                pculturedescription={dataCulture ? item.cultureElementSectionFields.description : ''}
              />
            </SwiperSlide>
          )
        }) : null}
      </Swiper>
    </section>
  );
};
