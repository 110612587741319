import React, {useState, useEffect, useRef} from "react";
import { Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

import backgroundSkyBlue from "../../assets/img/background/FONDO__CELESTE_V2.jpg";
import HeroTextMylodon from "../../assets/img/tags/hoppy-lager.png";
import MylodonFooter from "../../assets/img/cervezas-leyenda/pmylodon-mb.png";
import Mylodon from "../../assets/img/cervezas-leyenda/Mylodon.png";
import MylodonDesktop from "../../assets/img/cervezas-leyenda/pmilodon.png";

import backgroundYellow from "../../assets/img/background/FONDO_AMARILLO.jpg";
import SmilodonHeroText from "../../assets/img/tags/red-lager.png";
import Smilodon from "../../assets/img/cervezas-leyenda/Smilodon.png";
import SmilogonFooter from "../../assets/img/cervezas-leyenda/psmilodon-mb.png";
import SmilodonDesktop from "../../assets/img/cervezas-leyenda/psmilodon.png";

import BackgroundOrange from "../../assets/img/background/FONDO_NARANJO_V3.jpg";
import ChulesauruHeroText from "../../assets/img/tags/lotus-lager.png";
import Chilesaurus from "../../assets/img/cervezas-leyenda/Chilesaurus.png";
import ChilesaurusFooter from "../../assets/img/cervezas-leyenda/pchilesaurus-mb.png";
import ChileSaurusDesktop from "../../assets/img/cervezas-leyenda/pchilesaurus.png";

import HeroText from "../../assets/tag-black.png";
import backgroundBlack from "../../assets/img/background/FONDO_NEGRO_V3.jpg";
import Megaloceros from "../../assets/img/cervezas-leyenda/Megaloceros.png";
import MegalocerosBtn from "../../assets/img/cervezas-leyenda/pmegaloceros-mb.png";
import MegalocerosDesk from "../../assets/img/cervezas-leyenda/pmegaloceros.png";

import ibuBlack from "../../assets/img/cervezas-leyenda/extra/ibu-black.png";
import ibuHoppy from "../../assets/img/cervezas-leyenda/extra/ibu-hoppy.png";
import ibuLotus from "../../assets/img/cervezas-leyenda/extra/ibu-lotus.png";
import ibuRed from "../../assets/img/cervezas-leyenda/extra/ibu-red.png";

import espumaBlack from "../../assets/img/cervezas-leyenda/extra/espuma-black.png";
import espumaHoppy from "../../assets/img/cervezas-leyenda/extra/espuma-hoppy.png";
import espumaLotus from "../../assets/img/cervezas-leyenda/extra/espuma-lotus.png";
import espumaRed from "../../assets/img/cervezas-leyenda/extra/espuma-red.png";

import silverRed from "../../assets/img/cervezas-leyenda/extra/silver-red.png";
import winnerBlack from "../../assets/img/cervezas-leyenda/extra/winner-black.png";

import galleryBlack1 from '../../assets/gallery/black-1.jpg'
import galleryRed1 from '../../assets/gallery/red-1.jpg'
import galleryHoppy1 from '../../assets/gallery/hoppy-1.jpg'
import galleryHoppy2 from '../../assets/gallery/hoppy-2.jpg'
import galleryHoppy3 from '../../assets/gallery/hoppy-3.jpg'
import galleryHoppy4 from '../../assets/gallery/hoppy-4.jpg'
import galleryLotus1 from '../../assets/gallery/lotus-1.jpg'
import galleryLotus2 from '../../assets/gallery/lotus-2.jpg'
import galleryLotus3 from '../../assets/gallery/lotus-3.jpg'

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import "./CardCerveza.scss";

export const CardCerveza = () => {
  const swiperRefBlack = useRef();
  const swiperRefYellow = useRef();
  const swiperRefSkyBlue = useRef();
  const swiperRefSkyOrange = useRef();

  const [dataBeers, setDataBeers] = useState(null);

  useEffect(() => {
    const requestOptionsCardBeer =  {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query : `
          query {
            beerSections {
                nodes {
                    title
                    beerSectionFields {
                      beerTitle {
                          slug
                          sourceUrl
                      }
                      beerDescription
                      beerFirstDetailTitle
                      beerFirstDetailContent
                      beerSecondDetailTitle
                      beerSecondDetailContent
                      beerThirdDetailTitle
                      beerThirdDetailContent
                      beerFirstDetailImage {
                          slug
                          sourceUrl
                      }
                      beerSecondDetailImage {
                          slug
                          sourceUrl
                      }
                      beerThirdDetailImage {
                          slug
                          sourceUrl
                      }
                      gl
                      cc
                      viewVideoButton
                      urlViewVideoButton
                      buyButton
                      urlBuyButton
                      animalName
                      animalDetailButton
                      beerImage {
                          slug
                          sourceUrl
                      }
                      animalDetailTitle
                      animalDetail
                      animalDataTitle
                      animalFirstData
                      animalFirstDataMetric
                      animalSecondData
                      animalSecondDataMetric
                      animalThirdData
                      animalFourthData
                      animalFourthDataMetric
                      animalImage {
                          slug
                          sourceUrl
                      }
                      background {
                          slug
                          sourceUrl
                      }
                      extraImage1 {
                          slug
                          sourceUrl
                      }
                      extraImage2 {
                          slug
                          sourceUrl
                      }
                      extraImage3 {
                          slug
                          sourceUrl
                      }
                      extraContent
                  }
                }
            }
          }
        `,
      })
    }

    const dataFetch = async () => {
      const dataBeers = await (
        await fetch("https://api.cervezapatagonia.cl/index.php?graphql", requestOptionsCardBeer)
      ).json();

      console.log(dataBeers)
      setDataBeers(dataBeers);
    };

    dataFetch();
  }, []);

  return (
    <>
    {dataBeers ? dataBeers.data.beerSections.nodes.map((beer, index) => {
      return ((
        <section
          key={index}
          className={`container-beerHistory ${beer.beerSectionFields.animalName === 'Megaloceros' ? 'black' : beer.beerSectionFields.animalName === 'Smilodon' ? 'yellow' : beer.beerSectionFields.animalName === 'Chilesaurus' ? 'skyBlue' : 'orange'}`}
          style={{ backgroundImage: "url(" + beer.beerSectionFields.background.sourceUrl + ")" }}
          id={beer.beerSectionFields.beerTitle.slug.replace('-', '')}
        >
          {beer.beerSectionFields.animalName === 'Megaloceros' ? <button className="prev-btn-swiper" onClick={() => swiperRefBlack.current?.slidePrev()}>{'<'}</button> : beer.beerSectionFields.animalName === 'Smilodon' ? <button className="prev-btn-swiper" onClick={() => swiperRefYellow.current?.slidePrev()}>{'<'}</button> : beer.beerSectionFields.animalName === 'Chilesaurus' ? <button className="prev-btn-swiper" onClick={() => swiperRefSkyBlue.current?.slidePrev()}>{'<'}</button> : <button className="prev-btn-swiper" onClick={() => swiperRefSkyOrange.current?.slidePrev()}>{'<'}</button>}

          <Swiper
            centeredSlides={true}
            speed={800}
            effect="fade"
            className={`beer-swiper ${beer.beerSectionFields.animalName === 'Megaloceros' ? 'black' : beer.beerSectionFields.animalName === 'Smilodon' ? 'yellow' : beer.beerSectionFields.animalName === 'Chilesaurus' ? 'skyBlue' : 'orange'}`}
            pagination={{ clickable: true }}
            onBeforeInit={(swiper) => {
              if(beer.beerSectionFields.animalName === 'Megaloceros') {
                swiperRefBlack.current = swiper;
              }
              if(beer.beerSectionFields.animalName === 'Smilodon') {
                swiperRefYellow.current = swiper;
              }
              if(beer.beerSectionFields.animalName === 'Chilesaurus') {
                swiperRefSkyBlue.current = swiper;
              }
              if(beer.beerSectionFields.animalName === 'Mylodon') {
                swiperRefSkyOrange.current = swiper;
              }
            }}
            modules={[EffectFade, Navigation, Pagination, Scrollbar, A11y]}
          >
            <SwiperSlide>
              <article className="card-beer first">
                <div className="header-card">
                  <img alt="" className="bannerName" src={dataBeers ? beer.beerSectionFields.beerTitle.sourceUrl : undefined} />
                  <h3 className="name">{dataBeers ? beer.beerSectionFields.animalName : ''}</h3>
                  <p className="description" onClick={() => {
                    if(beer.beerSectionFields.animalName === 'Megaloceros') {
                      swiperRefBlack.current?.slideNext();
                    }
                    if(beer.beerSectionFields.animalName === 'Smilodon') {
                      swiperRefYellow.current?.slideNext();
                    }
                    if(beer.beerSectionFields.animalName === 'Chilesaurus') {
                      swiperRefSkyBlue.current?.slideNext();
                    }
                    if(beer.beerSectionFields.animalName === 'Mylodon') {
                      swiperRefSkyOrange.current?.slideNext();
                    }
                  }}>
                    {dataBeers ? beer.beerSectionFields.animalDetailButton : ''}
                  </p>
                  <img alt="" src={dataBeers ? beer.beerSectionFields.beerImage.sourceUrl : undefined} />
                </div>

                <div className="body-card">
                  <img alt="" className="bannerName" src={dataBeers ? beer.beerSectionFields.beerTitle.sourceUrl : undefined} />

                  <div className="content">
                    <p className="textMobile">
                      {dataBeers ? beer.beerSectionFields.beerDescription : ''}
                    </p>

                    <p className="textDesktop">
                      {dataBeers ? beer.beerSectionFields.beerDescription : ''}
                    </p>

                    <ul className="beer-info">
                      <li>
                        <div className="type">
                          <p>{dataBeers ? beer.beerSectionFields.beerFirstDetailTitle : ''}</p>
                          <p>{dataBeers ? beer.beerSectionFields.beerFirstDetailContent : ''}</p>
                        </div>
                        <img
                          alt={dataBeers ? beer.beerSectionFields.beerFirstDetailImage.slug : ''}
                          className="beer-extra"
                          src={dataBeers ? beer.beerSectionFields.beerFirstDetailImage.sourceUrl : ''}
                        />
                      </li>

                      <li>
                        <div className="type">
                          <p>{dataBeers ? beer.beerSectionFields.beerSecondDetailTitle : ''}</p>
                          <p>{dataBeers ? beer.beerSectionFields.beerSecondDetailContent : ''}</p>
                        </div>
                        <img
                          alt={dataBeers ? beer.beerSectionFields.beerSecondDetailImage.slug : ''}
                          className="beer-extra"
                          src={dataBeers ? beer.beerSectionFields.beerSecondDetailImage.sourceUrl : ''}
                        />
                      </li>

                      <li>
                        <div className="type">
                          <p>{dataBeers ? beer.beerSectionFields.beerThirdDetailTitle : ''}</p>
                          <p>{dataBeers ? beer.beerSectionFields.beerThirdDetailContent : ''}</p>
                        </div>
                        <img
                          alt={''}
                          className="beer-extra"
                          src={beer.beerSectionFields.beerThirdDetailImage ? beer.beerSectionFields.beerThirdDetailImage.sourceUrl : ''}
                        />
                      </li>
                    </ul>

                    <div className="amount">
                      <p className="degrees">
                        {dataBeers ? beer.beerSectionFields.gl : ''}<span>°</span> GL
                      </p>
                      <p className="quantity">{dataBeers ? beer.beerSectionFields.cc : ''} cc</p>
                    </div>

                    <div className="container-buttons">
                      <a
                        href={dataBeers ? beer.beerSectionFields.urlBuyButton : ''}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="fa-solid fa-cart-shopping"></i>
                        Comprar
                      </a>
                    </div>
                  </div>
                </div>
              </article>
            </SwiperSlide>

            <SwiperSlide>
              <article className="card-beer second">
                <div className="header-card">
                  <img alt="" className="bannerName" src={dataBeers ? beer.beerSectionFields.beerTitle.sourceUrl : undefined} />
                  <p className="description">
                    {dataBeers ? beer.beerSectionFields.animalDetailTitle : undefined}
                  </p>
                </div>

                <div className="body-card">
                  <div className="content">
                    <img alt="" className="bannerName" src={dataBeers ? beer.beerSectionFields.beerTitle.sourceUrl : undefined} />
                    <p>
                      {dataBeers ? beer.beerSectionFields.beerDescription : undefined}
                    </p>

                    <ul className="beer-info">
                      <li>
                        <div className="type">
                          <p>{dataBeers ? beer.beerSectionFields.beerFirstDetailTitle : ''}</p>
                          <p>{dataBeers ? beer.beerSectionFields.beerFirstDetailContent : ''}</p>
                        </div>
                        <img
                          alt={dataBeers ? beer.beerSectionFields.beerFirstDetailImage.slug : ''}
                          className="beer-extra"
                          src={dataBeers ? beer.beerSectionFields.beerFirstDetailImage.sourceUrl : ''}
                        />
                      </li>

                      <li>
                        <div className="type">
                          <p>{dataBeers ? beer.beerSectionFields.beerSecondDetailTitle : ''}</p>
                          <p>{dataBeers ? beer.beerSectionFields.beerSecondDetailContent : ''}</p>
                        </div>
                        <img
                          alt={dataBeers ? beer.beerSectionFields.beerSecondDetailImage.slug : ''}
                          className="beer-extra"
                          src={dataBeers ? beer.beerSectionFields.beerSecondDetailImage.sourceUrl : ''}
                        />
                      </li>

                      <li>
                        <div className="type">
                          <p>{dataBeers ? beer.beerSectionFields.beerThirdDetailTitle : ''}</p>
                          <p>{dataBeers ? beer.beerSectionFields.beerThirdDetailContent : ''}</p>
                        </div>
                        <img
                          alt={''}
                          className="beer-extra"
                          src={beer.beerSectionFields.beerThirdDetailImage ? beer.beerSectionFields.beerThirdDetailImage.sourceUrl : ''}
                        />
                      </li>
                    </ul>

                    <div className="amount">
                      <p className="degrees">
                        {dataBeers ? beer.beerSectionFields.gl : ''}<span>°</span> GL
                      </p>
                      <p className="quantity">{dataBeers ? beer.beerSectionFields.cc : ''} cc</p>
                    </div>

                    <div className="container-buttons">
                      <a
                        href={dataBeers ? beer.beerSectionFields.urlBuyButton : ''}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="fa-solid fa-cart-shopping"></i>
                        Comprar
                      </a>
                    </div>
                  </div>

                  <div className="content">
                    <p className="description character">
                      {dataBeers ? beer.beerSectionFields.animalDetailTitle : ''}
                    </p>

                    <p>
                      {dataBeers ? beer.beerSectionFields.animalDetail : undefined}
                    </p>

                    <h2>
                      Datos <span>Freak</span>
                    </h2>

                    <ul className="beer-info">
                      <li>
                        <div className="type">
                          <p>
                            <span className="text-strech-m">
                              {dataBeers ? beer.beerSectionFields.animalFirstData.replace('_', beer.beerSectionFields.animalFirstDataMetric) : ''}
                            </span>
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="type">
                          <p>{dataBeers ? beer.beerSectionFields.animalSecondData.replace('_', beer.beerSectionFields.animalSecondDataMetric) : ''}</p>
                        </div>
                      </li>

                      <li>
                        <div className="type">
                          <p>
                            {dataBeers ? beer.beerSectionFields.animalThirdData.replace('_', beer.beerSectionFields.animalThirdDataMetric) : ''}
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="type">
                          <p>
                            {beer.beerSectionFields.animalFourthData ? beer.beerSectionFields.animalFourthData.replace('_', beer.beerSectionFields.animalFourthDataMetric) : beer.beerSectionFields.animalFourthData}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="content">
                    <img src={dataBeers ? beer.beerSectionFields.animalImage.sourceUrl : undefined} alt="" />
                  </div>
                </div>

                {beer.beerSectionFields.animalName === 'Megaloceros' ? <img className="btn-image" src={MegalocerosBtn} alt="" /> : ''}
                {beer.beerSectionFields.animalName === 'Smilodon' ? <img className="btn-image" src={SmilogonFooter} alt="" /> : ''}
                {beer.beerSectionFields.animalName === 'Chilesaurus' ? <img className="btn-image" src={ChilesaurusFooter} alt="" /> : ''}
                {beer.beerSectionFields.animalName === 'Mylodon' ? <img className="btn-image" src={MylodonFooter} alt="" /> : ''}
              </article>
            </SwiperSlide>

            <SwiperSlide>
              <article className="card-beer third">
                <div className="header-card">
                  <img alt="" className="bannerName" src={dataBeers ? beer.beerSectionFields.beerTitle.sourceUrl : undefined} />
                  <h3 className="name">{dataBeers ? beer.beerSectionFields.animalName : ''}</h3>
                </div>

                <div className="body-card">
                  <div className="content">
                    <img alt="" className="bannerName" src={dataBeers ? beer.beerSectionFields.beerTitle.sourceUrl : undefined} />
                    <p>
                      {dataBeers ? beer.beerSectionFields.beerDescription : undefined}
                    </p>

                    <ul className="beer-info">
                      <li>
                        <div className="type">
                          <p>{dataBeers ? beer.beerSectionFields.beerFirstDetailTitle : ''}</p>
                          <p>{dataBeers ? beer.beerSectionFields.beerFirstDetailContent : ''}</p>
                        </div>
                        <img
                          alt={dataBeers ? beer.beerSectionFields.beerFirstDetailImage.slug : ''}
                          className="beer-extra"
                          src={dataBeers ? beer.beerSectionFields.beerFirstDetailImage.sourceUrl : ''}
                        />
                      </li>

                      <li>
                        <div className="type">
                          <p>{dataBeers ? beer.beerSectionFields.beerSecondDetailTitle : ''}</p>
                          <p>{dataBeers ? beer.beerSectionFields.beerSecondDetailContent : ''}</p>
                        </div>
                        <img
                          alt={dataBeers ? beer.beerSectionFields.beerSecondDetailImage.slug : ''}
                          className="beer-extra"
                          src={dataBeers ? beer.beerSectionFields.beerSecondDetailImage.sourceUrl : ''}
                        />
                      </li>

                      <li>
                        <div className="type">
                          <p>{dataBeers ? beer.beerSectionFields.beerThirdDetailTitle : ''}</p>
                          <p>{dataBeers ? beer.beerSectionFields.beerThirdDetailContent : ''}</p>
                        </div>
                        <img
                          alt={''}
                          className="beer-extra"
                          src={beer.beerSectionFields.beerThirdDetailImage ? beer.beerSectionFields.beerThirdDetailImage.sourceUrl : ''}
                        />
                      </li>
                    </ul>

                    <div className="amount">
                      <p className="degrees">
                        {dataBeers ? beer.beerSectionFields.gl : ''}<span>°</span> GL
                      </p>
                      <p className="quantity">{dataBeers ? beer.beerSectionFields.cc : ''} cc</p>
                    </div>

                    <div className="container-buttons">
                      <a
                        href={dataBeers ? beer.beerSectionFields.urlBuyButton : ''}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="fa-solid fa-cart-shopping"></i>
                        Comprar
                      </a>
                    </div>
                  </div>

                  <div className="content">
                    <Swiper
                      centeredSlides={false}
                      className="beer-swiperInside"
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      pagination={false}
                      navigation={false}
                      slidesPerView={1}
                      spaceBetween={10}
                    >
                      <SwiperSlide>
                        <img className="body-image" src={beer.beerSectionFields.extraImage1 ? beer.beerSectionFields.extraImage1.sourceUrl : galleryBlack1} alt="" />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>

                {beer.beerSectionFields.animalName === 'Megaloceros' ? <img className="btn-image" src={MegalocerosBtn} alt="" /> : ''}
                {beer.beerSectionFields.animalName === 'Smilodon' ? <img className="btn-image" src={SmilogonFooter} alt="" /> : ''}
                {beer.beerSectionFields.animalName === 'Chilesaurus' ? <img className="btn-image" src={ChilesaurusFooter} alt="" /> : ''}
                {beer.beerSectionFields.animalName === 'Mylodon' ? <img className="btn-image" src={MylodonFooter} alt="" /> : ''}
              </article>
            </SwiperSlide>
          </Swiper>

          {beer.beerSectionFields.animalName === 'Megaloceros' ? <button className="next-btn-swiper" onClick={() => swiperRefBlack.current?.slideNext()}>{'>'}</button> : beer.beerSectionFields.animalName === 'Smilodon' ? <button className="next-btn-swiper" onClick={() => swiperRefYellow.current?.slideNext()}>{'>'}</button> : beer.beerSectionFields.animalName === 'Chilesaurus' ? <button className="next-btn-swiper" onClick={() => swiperRefSkyBlue.current?.slideNext()}>{'>'}</button> : <button className="next-btn-swiper" onClick={() => swiperRefSkyOrange.current?.slideNext()}>{'>'}</button>}
        </section>
      ))
    }): ''}
    </>
  );
};
