import React from "react";
import { CardCerveza } from "../../layouts/CardCerveza/CardCerveza";

export const Cervezas = () => {
  return (
    <div id="variedades">
      <CardCerveza />
    </div>
  );
};
