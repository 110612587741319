import React, {useState, useEffect} from "react";
import "./Contactanos.scss";

import pMilodon from '../../assets/img/cervezas-leyenda/pmilodon.png'
import pChilesaurus from '../../assets/img/cervezas-leyenda/pchilesaurus.png'

export const Contactanos = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query : `
          query q($id: ID!, $idType: OneSectionIdType!) {
            oneSection(id: $id, idType: $idType) {
              contactSectionFields {
                title
                inputName
                inputEmail
                inputMessage
                inputButton
                logo {
                  slug
                  sourceUrl
                }
                socialNetworksTitle
                iconFacebook {
                  slug
                  sourceUrl
                }
                iconInstagram {
                  slug
                  sourceUrl
                }
                iconYoutube {
                  slug
                  sourceUrl
                }
                socialNetworksEmail
                background {
                  slug
                  sourceUrl
                }
                leftImage {
                  slug
                  sourceUrl
                }
                rightImage {
                  slug
                  sourceUrl
                }
              }
            }
          }
        `,
        variables: {
          id: 'contact-section',
          idType: 'SLUG'
        }
      })
    };

    const dataFetch = async () => {
      const data = await (
        await fetch("https://api.cervezapatagonia.cl/index.php?graphql", requestOptions)
      ).json();

      setData(data);
    };

    dataFetch();
  }, []);

  return (
    <section className="contact" id="contacto">
      <img className='floatLeft' src={data ? data.data.oneSection.contactSectionFields.leftImage.sourceUrl : pChilesaurus} alt="" />

      <div className="content">
        <h2>{data ? data.data.oneSection.contactSectionFields.title : 'Contáctanos'}</h2>
        <form>
          <div className="group-input">
            <input type="text" name="" id="" placeholder={data ? data.data.oneSection.contactSectionFields.inputName : 'Nombre'} />
          </div>
          <div className="group-input">
            <input type="email" name="" id="" placeholder={data ? data.data.oneSection.contactSectionFields.inputEmail : 'Email'} />
          </div>
          <div className="group-input">
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              placeholder={data ? data.data.oneSection.contactSectionFields.inputMessage : 'Mensaje'}
            ></textarea>
          </div>
          <button type="submit">{data ? data.data.oneSection.contactSectionFields.inputButton : 'Enviar'}</button>
        </form>

        <div className="group-others-logo">
          <div className="others">
            <p>{data ? data.data.oneSection.contactSectionFields.socialNetworksTitle : 'o Conversa con nosotros a través de nuestras redes'}</p>
            <div className="links">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/cerveza.patagonia.chile"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={data ? data.data.oneSection.contactSectionFields.iconFacebook.sourceUrl : require("../../assets/img/icon/fb.svg").default}
                      alt="Facebook"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/cerveza.patagonia.cl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={data ? data.data.oneSection.contactSectionFields.iconInstagram.sourceUrl : require("../../assets/img/icon/ins.svg").default}
                      alt="Instagram"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@cervezapatagoniachile9252/featured"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={data ? data.data.oneSection.contactSectionFields.iconYoutube.sourceUrl : require("../../assets/img/icon/yt.svg").default}
                      alt="Youtube"
                    />
                  </a>
                </li>
              </ul>
              <a href="http://">{data ? data.data.oneSection.contactSectionFields.socialNetworksEmail : '@cerveza.patagonia.cl'}</a>
            </div>
          </div>
          <div className="container-logo">
            <img
              className="logo"
              src={data ? data.data.oneSection.contactSectionFields.logo.sourceUrl : require("../../assets/img/icon/logo.svg").default}
              alt="Logo Cerveza Patagonia"
            />
          </div>
        </div>
      </div>

      <img className="floatRight" src={data ? data.data.oneSection.contactSectionFields.rightImage.sourceUrl : pMilodon} alt="" />
    </section>
  );
};
