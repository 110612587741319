import React, {useState, useEffect} from "react";
import "./Historia.scss";

// images
import historia from "../../assets/img/text/tag-historia.png";
import mobile1 from "../../assets/img/deco/La-cerveza.png";
import mobile2 from "../../assets/img/deco/historia-mobile-2.png";
import mobile3 from "../../assets/img/deco/sketch-beers.png";
import desktop1 from "../../assets/img/deco/historia-desk-1.png";
import desktop2 from "../../assets/img/deco/haciendo-ruido.png";

export const Historia = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query : `
          query q($id: ID!, $idType: OneSectionIdType!) {
            oneSection(id: $id, idType: $idType) {
              historySectionFields {
                title
                titleImage {
                  slug
                  sourceUrl
                }
                topDescription
                topImage {
                  slug
                  sourceUrl
                }
                bottomTitle {
                  slug
                  sourceUrl
                }
                bottomDescription
                bottomBeers {
                  slug
                  sourceUrl
                }
                background {
                  slug
                  sourceUrl
                }
              }
            }
          }
        `,
        variables: {
          id: 'history-section',
          idType: 'SLUG'
        }
      })
    };

    const dataFetch = async () => {
      const data = await (
        await fetch("https://api.cervezapatagonia.cl/index.php?graphql", requestOptions)
      ).json();

      setData(data);
    };

    dataFetch();
  }, []);

  return (
    <section className="history" id="historia">
      <div className="title">
        <h2>{data ? data.data.oneSection.historySectionFields.title : 'Nuestra'}</h2>
        <img src={data ? data.data.oneSection.historySectionFields.titleImage.sourceUrl : historia} alt="" />
      </div>

      <div className="container-mobile">
        <article className="article-mobile article-mobile1">
          <img
            className="img-mb"
            src={mobile1}
            alt="La cerveza está para disfrutarla"
          />

          <p>
            por eso buscamos disponibilizar a todos los consumidores una
            <span> cerveza de calidad artesanal</span> junto con la frescura de
            una lager tradicional.
          </p>
        </article>

        <article className="article-mobile article-mobile2">
          <img
            className="img-mb"
            src={data ? data.data.oneSection.historySectionFields.bottomTitle.sourceUrl : mobile2}
            alt="La cerveza está para disfrutarla"
          />

          <p>
            Nos encanta innovar con <span>sabores lager épicos</span> y latas de
            alto impacto inspiradas en <span>criaturas legendarias</span> de
            nuestro planeta.
          </p>
        </article>

        <img className="beers" src={data ? data.data.oneSection.historySectionFields.bottomBeers.sourceUrl : mobile3} alt="" />
      </div>

      <div className="container-desktop">
        <article className="article-desk article-desk1">
          <img
            className="img-desk"
            src={data ? data.data.oneSection.historySectionFields.topImage.sourceUrl : desktop1}
            alt="La cerveza está para disfrutarla"
          />
          <p>
            por eso buscamos disponibilizar a todos los consumidores una
            <span> cerveza de calidad artesanal</span> junto con la frescura de
            una lager tradicional.
          </p>
        </article>
        <article className="article-desk article-desk2">
          <img className="beers" src={mobile3} alt="" />
          <div className="right">
            <img
              className="img-desk"
              src={desktop2}
              alt="La cerveza está para disfrutarla"
            />
            <p>
              Nos encanta innovar con <span>sabores lager épicos</span> y latas
              de alto impacto inspiradas en <span>criaturas legendarias</span>{" "}
              de nuestro planeta.
            </p>
          </div>
        </article>
      </div>
    </section>
  );
};
