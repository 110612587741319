import React, { useState, useEffect } from "react";
import "./AgeVerify.scss";

import logo from "../../assets/img/icon/logo.svg";
import video from "../../assets/video/agegate.mp4";
import poster from "../../assets/img/background/poster.png";

export const AgeVerify = () => {
  const [validateAge, setValidateAge] = useState("");
  const [data, setData] = useState(null);

  useEffect(() => {
    const isValidateAge = localStorage.getItem("validateAge");

    const isOlder = document.getElementById("verifyYes");
    const isNotOlder = document.getElementById("verifyNo");
    const younger = document.getElementById("younger");

    if (isValidateAge) {
      document.querySelector(".ageverify-modal").classList.add("none");
      document.querySelector("body").classList.remove("validate");
    }

    isOlder.addEventListener("click", (e) => {
      e.preventDefault();
      localStorage.setItem("validateAge", true);
      setValidateAge(true);
    });

    isNotOlder.addEventListener("click", (e) => {
      e.preventDefault();
      younger.style.display = "block";
    });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query : `
          query q($id: ID!, $idType: OneSectionIdType!) {
            oneSection(id: $id, idType: $idType) {
              welcomeSectionFields {
                title
                buttonAccept
                buttonDecline
                logo {
                  slug
                  sourceUrl
                }
                background {
                  slug
                  sourceUrl
                }
              }
            }
          }
        `,
        variables: {
          id: 'welcome-section',
          idType: 'SLUG'
        }
      })
    };

    const dataFetch = async () => {
      const data = await (
        await fetch("https://api.cervezapatagonia.cl/index.php?graphql", requestOptions)
      ).json();

      setData(data);
    };

    dataFetch();
  }, [validateAge]);

  return (
    <div className={!validateAge ? "ageverify-modal" : "ageverify-modal none"}>
      <div className="container-video">
        <video className="video" autoPlay muted loop poster={poster}>
          <source src={video} type="video/mp4" />
        </video>
        <div className="overlay"></div>
      </div>
      <div className="container-verify">
        <div className="verify">
          <div className="left">
            <p>
              ¿Eres Mayor
              <span className="outline">
                de <span className="number">18</span> años?
              </span>
            </p>
          </div>
          <div className="right">
            <button type="button" id="verifyYes">
              {data ? data.data.oneSection.welcomeSectionFields.buttonAccept : 'Si'}
            </button>
            <button type="button" id="verifyNo">
              {data ? data.data.oneSection.welcomeSectionFields.buttonDecline : 'No'}
            </button>
          </div>
        </div>
        <p className="younger" id="younger">
          Necesitas ser mayor de <span>18</span> años para ingresar
        </p>
        <img className="logo" src={data ? data.data.oneSection.welcomeSectionFields.logo.sourceUrl : logo} alt="Logo cerveza Patagonia" />
      </div>
    </div>
  );
};
