import React, {useState, useEffect} from "react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./Hero.scss";

import text from "../../assets/img/text/text-hero.png";

SwiperCore.use([Pagination, Navigation]);

export const Hero = () => {
  const [dataHome, setDataHome] = useState(null);
    const [loading, setLoading] = useState(true);

  useEffect(() => {
    const requestOptionsHome =  {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query : `
          query {
            homeSections {
              nodes {
                  title
                  homeSectionFields {
                    image {
                        slug
                        sourceUrl
                    }
                    background {
                        slug
                        sourceUrl
                    }
                  }
              }
            }
          }
        `,
      })
    }

    const dataFetch = async () => {
      const dataHome = await (
        await fetch("https://api.cervezapatagonia.cl/index.php?graphql", requestOptionsHome)
      ).json();

      setDataHome(dataHome.data.homeSections.nodes);
         setLoading(false);
    };

    dataFetch();

  }, []);

    if(loading) {
    return (
      <div className="loading" style={{height: '100vh', position: 'relative', zIndex: 10, background: 'white'}}></div>
    )
  }

  return (
    <section className="hero">
      <Swiper
        pagination={true}
        navigation={true}
        centeredSlides={true}
        modules={[Pagination, Navigation]}
        className="hero-swiper"
      >
        {dataHome ? dataHome.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="hero-card" style={{backgroundImage: `url(${item.homeSectionFields.background.sourceUrl})`}}>
                <img className="hero-img" src={item.homeSectionFields.image.sourceUrl} alt="" />
              </div>
            </SwiperSlide>
          )
        }) : (
          <>
            <SwiperSlide>
              <div className="hero-card">
                <img className="hero-img" src={text} alt="" />
              </div>
            </SwiperSlide>
          </>
        )}
      </Swiper>
    </section>
  );
};
