import React, {useState, useEffect} from "react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./Leyendas.scss";

// Images
import red from "../../assets/img/cervezas/SMILODON_IZQUIERDA.png";
import hoppy from "../../assets/img/cervezas/MYLODON_IZQUIERDA.png";
import lotus from "../../assets/img/cervezas/CHILESAURUS_IZQUIERDA.png";
import black from "../../assets/img/cervezas/MEGALOCEROS_IZQUIERDA.png";

SwiperCore.use([Pagination, Navigation]);

export const Leyendas = () => {
  const [dataLeyendas, setDataLeyendas] = useState(null);

  useEffect(() => {
    const requestOptionsHome =  {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query : `
          query {
            productSections {
              nodes {
                title
                productsBeerSectionFields {
                  beerImage {
                    slug
                    sourceUrl
                  }
                  beerTitle
                  animalName
                  cc
                  gl
                  buyButton
                  urlBuyButton
                }
              }
            }
          }
        `,
      })
    }

    const dataFetch = async () => {
      const dataLeyendas = await (
        await fetch("https://api.cervezapatagonia.cl/index.php?graphql", requestOptionsHome)
      ).json();

      setDataLeyendas(dataLeyendas.data.productSections.nodes);
    };

    dataFetch();
  }, []);

  return (
    <section className="section-beers" id="leyendas">
      <h2>
        <span>Descubre </span>Nuestras leyendas
      </h2>

      <Swiper
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        slidesPerView={2}
        spaceBetween={20}
        className="beers-swiper"
        breakpoints={{
          460: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        }}
      >
        {dataLeyendas ? dataLeyendas.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="beers-card">
                <article className="card-leyenda">
                  <img src={dataLeyendas ? item.productsBeerSectionFields.beerImage.sourceUrl : undefined} alt="" />
                  <p className="tag">{dataLeyendas ? item.title : ''}</p>
                  <p className="name">{dataLeyendas ? item.productsBeerSectionFields.animalName : ''}</p>
                  <div className="info">
                    <p className="cc1">
                      {dataLeyendas ? item.productsBeerSectionFields.cc : ''}<span className="cc2">cc</span>
                    </p>
                    <p className="gl">
                      {dataLeyendas ? item.productsBeerSectionFields.gl : ''}<span>º</span> GL
                    </p>
                  </div>
                  <a
                    href={dataLeyendas ? item.productsBeerSectionFields.urlBuyButton : ''}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-solid fa-cart-shopping"></i>
                    {dataLeyendas ? item.productsBeerSectionFields.buyButton : ''}
                  </a>
                </article>
              </div>
            </SwiperSlide>
          )
        }) : undefined}
      </Swiper>
    </section>
  );
};
