import React, {useState, useEffect} from "react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./PNews.scss";

import { CardNews } from "../../layouts/CardNews/CardNews";

import img1 from "../../assets/img/PNews/PS - News.png";
import img2 from "../../assets/img/PNews/Lata - News.png";
import img3 from "../../assets/img/PNews/FP2 - News.png";
import img4 from "../../assets/img/PNews/FP - News.png";

SwiperCore.use([Pagination, Navigation]);

export const PNews = (props) => {
  const [dataNews, setDataNews] = useState(null);

  useEffect(() => {
    const requestOptionsNews =  {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query : `
          query {
            newsSections {
              nodes {
                newsBaseSectionFields {
                  title
                  fieldGroupName
                  background {
                    sourceUrl
                    slug
                  }
                  titleImage {
                    sourceUrl
                    slug
                  }
                }
                newsElementSectionFields {
                  description
                  detailBottomImage {
                    sourceUrl
                    slug
                  }
                  detailContent
                  detailSectionTitle
                  detailTitle
                  fieldGroupName
                  title
                  urlViewMoreButton
                  viewMoreButton
                  image {
                    sourceUrl
                    slug
                  }
                  detaileBottomImage {
                    sourceUrl
                    slug
                  }
                  detailSectionTitleImage {
                    sourceUrl
                    slug
                  }
                  detailImage {
                    sourceUrl
                    slug
                  }
                }
              }
            }
          }
        `,
      })
    }

    const dataFetch = async () => {
      const dataNews = await (
        await fetch("https://api.cervezapatagonia.cl/index.php?graphql", requestOptionsNews)
      ).json();

      setDataNews(dataNews.data.newsSections.nodes);
    };

    dataFetch();
  }, []);

  return (
    <section className="news" id="news">
      <h2>
        Patagonia <span>News.</span>
      </h2>
      <Swiper
        pagination={true}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="PNewsSwiper"
        breakpoints={{
          320: {
            slidesPerView: 1.3,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
      >
        {dataNews ? dataNews.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <CardNews
                id={dataNews ? item.newsElementSectionFields.urlViewMoreButton.split('/news/')[1] : null}
                pnewsimg={dataNews ? item.newsElementSectionFields.image.sourceUrl : null}
                pnewstitle={dataNews ? item.newsElementSectionFields.title : null}
                pnewsdescription={dataNews ? item.newsElementSectionFields.description : null}
              />
            </SwiperSlide>
          )
        }) : null}
      </Swiper>
    </section>
  );
};
